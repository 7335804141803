/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import NotificationAlert from "react-notification-alert";
import { Col, Container, Row } from "reactstrap";
import AdminService from "../../../services/adminService";
import AdminListTable from "./ListTable";
import SecurityConstant from "../../../util/securityConstant";

class AdminList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
    this.delete = this.delete.bind(this);
    this.notify = this.notify.bind(this);
  }

  componentDidMount() {
    AdminService.list(SecurityConstant.ROLE_ADMIN).then((response) => {
      console.log(response.data);
      this.setState({ list: response.data });
    });
  }

  delete(uniqueId) {
    AdminService.delete(uniqueId).then((response) => {
      console.log(response);
      this.notify(
        response.responseStatus.toLowerCase(),
        response.responseStatus,
        response.message
      );
      //TODO: TO be fixed with redux implementation
      //   this.props.history.push("/admin/dashboard");
      AdminService.list(SecurityConstant.ROLE_ADMIN).then((response) => {
        console.log(response.data);
        this.setState({ list: response.data });
      });
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    const { adminList } = this.state.list;
    let obj = this;
    console.log(adminList);
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <SimpleHeader
          name="List"
          parentName="Users"
          heading="ClashMate"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <AdminListTable
                adminComponentObj={obj}
                adminList={this.state.list}
                cardTitle="Users List"
                userRole={SecurityConstant.ROLE_ADMIN}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AdminList;

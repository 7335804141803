/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Badge, Button,
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import ParentService from "../../../services/parentService";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ProjectService from "../../../services/projectService";
import UtilService from "../../../services/utilService";
import routeVisibleForRole from "../../../util/routeVisibleForRole";
import _ from "lodash";
import FormInput from "../../../components/common/form/FormInput";
import {ProjectPermissions} from "../../../services/permissions";

class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      alert: null,
      filterSharedWith : "",
      filterName :"",
      filteredList : []
    };
    this.delete = this.delete.bind(this);
    this.notify = this.notify.bind(this);
    this.populateProjects = this.populateProjects.bind(this);
    this.filterProjectsByUserId = this.filterProjectsByUserId.bind(this);
  }

  componentDidMount() {
    this.populateProjects()
  }

  filterProjectsByUserId = (data, userId) => {
    console.log(data)
    return _.filter(data, project =>
        _.some(project.sharedWith, user => user.uniqueId === userId)
    );
  };

  populateProjects () {
    ProjectService.list().then((response) => {
      console.log(response);
      this.setState({list: response, filteredList : response});
      if(routeVisibleForRole(["ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3"])){
        let currentUserId = localStorage.getItem('userId')
        console.log(currentUserId)
        let filteredList = this.filterProjectsByUserId(response, currentUserId)
        this.setState({list: filteredList, filteredList : filteredList})
        console.log(this.state.list)
      }
    });
  }

  hideDeleteAlert = () => {
    this.setState({
      alert: null
    });
  };

  onChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
    console.log(this.state);
  }

  applyFilter = () => {
    const { list, filterName, filterSharedWith } = this.state;

    const filteredList = _.filter(list, (project) => {
      const nameMatches = filterName ? _.includes(_.toLower(project.name), _.toLower(filterName)) : true;
      const sharedWithMatches = filterSharedWith
          ? _.some(project.sharedWith, (sharedPerson) =>
              _.includes(_.toLower(sharedPerson.fullName), _.toLower(filterSharedWith))
          )
          : true;

      return nameMatches && sharedWithMatches;
    });

    this.setState({ filteredList });
  }

  clearFilter = () => {
    this.setState({filteredList : this.state.list, filterName : "", filterSurname : "", filterCompany : "" })
  }

  confirmDeleteAlert = (uniqueId) => {
    this.setState({
      alert: (
          <ReactBSAlert
              warning
              style={{display: "block"}}
              title="Are you sure?"
              onConfirm={() => this.delete(uniqueId)}
              onCancel={this.hideDeleteAlert}
              showCancel
              confirmBtnBsStyle="danger"
              confirmBtnText="Yes, delete it!"
              cancelBtnBsStyle="secondary"
              cancelBtnText="Cancel"
              btnSize=""
          >
            You won't be able to revert this!
          </ReactBSAlert>
      )
    });
  };

  delete(uniqueId) {
    this.setState({alert: null})
    ProjectService.delete(uniqueId).then((response) => {
      console.log(response);
      this.notify(
          response.responseStatus.toLowerCase(),
          response.responseStatus,
          response.message
      );
      //TODO: TO be fixed with redux implementation
      //   this.props.history.push("/admin/dashboard");
      this.populateProjects()
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
          <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
            <span data-notify="message">{message}</span>
          </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    let obj = this;
    return (
        <>
          <div className="rna-wrapper">
            <NotificationAlert ref="notificationAlert"/>
          </div>
          <SimpleHeader
              name="List"
              parentName="Project"
              heading="XareGrowth"
              newBtnUrl="/admin/project/create"
              newBtnText="Create Project"
          />
          {this.state.alert}
          <Container className="mt--6" fluid>
            <Row>
              <Col xl="12">
                <Card>
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Project List</h3>
                      </div>
                    </Row>
                    <Row>
                      <p style={{ fontStyle: "italic", color: "gray" }}>
                        Use the filters below to narrow down your search results.
                      </p>
                    </Row>
                    <Row>
                      {/*<Col sm={2}>
                        <FormInput
                            name="dateFrom"
                            className="form-control-label"
                            id="date"
                            placeholder="inputPlaceHolder.meetingReport.date"
                            type="date"
                            onChange={this.onChange}
                            value={this.state.dateFrom || ""}
                            label="Date From"
                        />
                      </Col>
                      <Col sm={2}>
                        <FormInput
                            name="dateTo"
                            className="form-control-label"
                            id="date"
                            placeholder="inputPlaceHolder.meetingReport.date"
                            type="date"
                            onChange={this.onChange}
                            value={this.state.dateTo || ""}
                            label="Date To"
                        />
                      </Col>*/}
                      <Col md="2">
                        <FormInput
                            name="filterName"
                            className="form-control-label"
                            id="projectName"
                            placeholder="Project Name"
                            type="text"
                            onChange={this.onChange}
                            label="Project"
                            value={this.state?.filterName || ""}
                            // errorMsg={this.props.errorObj?.name || ""}
                        />
                      </Col>
                      <Col md="2">
                        <FormInput
                            name="filterSharedWith"
                            className="form-control-label"
                            id="sharedWith"
                            placeholder="Shared With"
                            type="text"
                            onChange={this.onChange}
                            label="Shared With"
                            value={this.state?.filterSharedWith || ""}
                            // errorMsg={this.props.errorObj?.name || ""}
                        />
                      </Col>
                      <Col md={"2"}></Col>
                      <Col md={"2"}></Col>
                      <Col sm={3} style={{marginTop: "30px"}}>
                        <Button  size="md" outline color="default" type="button"
                        onClick={this.applyFilter}>
                          Apply Filter
                        </Button>
                        <Button  color="default"  size="md" outline type="button"
                        onClick={this.clearFilter}>
                          Clear Filter
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Date Created
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Name
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Shared With
                      </th>
                      <th scope="col"/>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {this.state.filteredList.map(function (value, index) {
                      return (
                          <tr key={index} style={{display:ProjectPermissions.read(value?.sharedWith)}}>
                            <td className="budget">{index + 1}</td>
                            <td className="budget">{value.dateCreated}</td>
                            <td className="budget">
                              <Badge
                                  className="badge-default"
                                  href="#pablo"
                                  onClick={e => {
                                    e.preventDefault()
                                    obj.props.history.push(
                                        `/admin/project/show/${value.uniqueId}`
                                    );
                                  }}
                              >
                                {value.name}
                              </Badge>
                            </td>
                            <td className="budget">{value.sharedWith.map(value => {
                              return <Badge size="lg"  key={UtilService.uuidv4()} color="primary">{value?.fullName}</Badge>
                            })}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                    color=""
                                    size="sm"
                                    className="btn-icon-only text-light"
                                >
                                  <i className="fas fa-ellipsis-v"/>
                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                >
                                  <DropdownItem style={{display:ProjectPermissions.read(value?.sharedWith)}}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.props.history.push(
                                            `/admin/project/show/${value.uniqueId}`
                                        );
                                      }}
                                  >
                                    Details
                                  </DropdownItem>
                                  <DropdownItem style={{display:ProjectPermissions.read(value?.sharedWith)}}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  obj.props.history.push(
                                                      `/admin/projectSummaries/${value.uniqueId}`
                                                  );
                                                }}
                                  >
                                    View Summaries
                                  </DropdownItem>
                                  <DropdownItem style={{display:ProjectPermissions.update(value?.sharedWith)}}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.props.history.push(
                                            `/admin/project/edit/${value.uniqueId}`
                                        );
                                      }}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                      style={{display:ProjectPermissions.delete()}}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.confirmDeleteAlert(`${value.uniqueId}`);
                                      }}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                      );
                    })}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
    );
  }
}

export default ProjectList;

import React, { Component } from "react";
import { FormGroup } from "reactstrap";

class SummarySeeMoreDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            summaryModalText: "", // State to hold the full text when "See More" is clicked
        };
    }

    componentDidMount() {
        const { contentData } = this.props; // Destructure contentData from props
        const text = contentData?.text || ""; // Fallback to empty string if undefined

        // Check if the text is longer than 50 characters
        let isLongText = typeof text === "string" && text.length > 100;
        let truncatedText = isLongText ? text.substring(0, 101) + "..." : text;
        this.setState({isLongText: isLongText, truncatedText: truncatedText,text:text});
    }

    render() {

        return (
                <p>
                    {this.state.truncatedText}
                    {this.state.isLongText && (
                        <span
                            onClick={() => {
                                this.props.handleSeeMore(this.state.text);
                            }} // Pass the full text to the handler
                            style={{
                                color: "blue",
                                cursor: "pointer",
                                marginLeft: "5px",
                                textDecoration: "underline",
                            }}
                        >
                            See More
                        </span>
                    )}
                </p>
        );
    }
}

export default SummarySeeMoreDisplay;

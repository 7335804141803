/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader, Col,
  Container,
  Form,
} from "reactstrap";
import AdminService from "../../../services/adminService";
import AdminForm from "./Form";
import FormInput from "../../../components/common/form/FormInput";
import UtilService from "../../../services/utilService";
import TenantService from "../../../services/tenantService";

class AdminCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorData: {},
      file:undefined,
      fileSelectUUID:UtilService.uuidv4()
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    console.log(this.state);
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData()
    data.append('file', this.state.file)
    data.append('adminRole', this.state?.adminRole)
    data.append('emailAddress', this.state?.emailAddress)
    data.append('firstName', this.state?.firstName)
    data.append('middleName', this.state?.middleName)
    data.append('lastName', this.state?.lastName)
    data.append('userCompanyName', this.state?.userCompanyName)
    AdminService.create(data).then((response) => {
      console.log(response.data);
      if (response.responseStatus === "SUCCESS") {
        this.props.history.push("/admin/list");
      } else {
        this.setState({ errorData: response.data });
        console.error("Error    ==>     " + response.message);
      }
    });
  }
  fileChange = (event) => {
    this.setState({file: event.target.files[0]})
    console.log(this.state)
  };


  render() {
    return (
      <>
        <SimpleHeader
          name="Create"
          parentName="User"
          heading="ClashMate"
          newBtnUrl="/admin/list"
          newBtnText="List All Users"
        />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3 className="mb-0">Create User</h3>
            </CardHeader>
            <CardBody>
              <Form role="form" onSubmit={this.handleSubmit}>
                <AdminForm
                  onChange={this.handleChange}
                  valueObj={this.state}
                  errorObj={this.state.errorData}
                  isEdit={false}
                  fileChange = {this.fileChange}
                  fileSelectUuid = {this.state.fileSelectUUID}
                />
                <div className="text-center">
                  <Button className="my-4" color="default" outline type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default AdminCreate;

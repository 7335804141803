/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle,
    Form,
    FormGroup,
    Modal,
    Row, Table,
    UncontrolledDropdown,
} from "reactstrap";
import SummaryService from "../../../services/summaryService";
import SummaryForm from "./Form";
import NotificationAlert from "react-notification-alert";
import _ from "lodash";
import NextStepsForm from "../nextSteps/Form";
import {nextStepStatus} from "../../../util/nextStepStatus";
import NextStepsService from "../../../services/nextStepsService";
import UtilService from "../../../services/utilService";
import AdminService from "../../../services/adminService";
import {SummaryPermissions, TaskPermissions} from "../../../services/permissions";
import ProjectService from "../../../services/projectService";
import routeVisibleForRole from "../../../util/routeVisibleForRole";
import SummarySeeMoreDisplay from "./SummarySeeMoreDisplay";

class SummaryDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentData: this.props?.summary,
            text: this.props?.summary?.text,
            sharedWith: this.props?.summary?.sharedWith,
            sharedWithProjects: this.props?.summary?.sharedWithProjects,
            uniqueId: this.props?.summary?.uniqueId,
            index: this.props?.index,
            errorData: {},
            selectedNextStepStatus: {key: "OPEN", value: "OPEN"},
            attendeesSelectKey: UtilService.uuidv4(),
            attendesForSelection: [],
            projectsSelectKey: UtilService.uuidv4(),
            projectsForSelection: [],
            summaryUID: this.props?.summary?.uniqueId,
            meetingReportUID: this.props?.summary?.meetingReport,
            nextSteps: [],
            summaryModalText:""
        };

        this.handleTaskSubmit = this.handleTaskSubmit.bind(this);
        this.handleNextStepStatusChange = this.handleNextStepStatusChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAttendeesChange = this.handleAttendeesChange.bind(this);
        this.handleUpdateSummarySubmit = this.handleUpdateSummarySubmit.bind(this)
    }

    componentDidMount() {
        // const {uniqueId} = this.props.match.params;
        // SummaryService.fetch(uniqueId).then((response) => {
        //     console.log(response.data);
        //     this.setState({contentData: response.data});
        // });
        AdminService.list().then((response) => {
            console.log(response);
            this.setState({attendesForSelection: response.data, attendeesSelectKey: UtilService.uuidv4()});
        });
        ProjectService.list().then((response) => {
            console.log(response);
            this.setState({projectsForSelection: response, projectsSelectKey: UtilService.uuidv4()});
        });

    }

    handleChange(event) {
        let value = event.target.value
        console.log(value)
        this.setState({[event.target.name]: value});
        if(event.target.name === "text"){
            this.setState((prevState) => ({
                contentData: {
                    ...prevState.contentData,   // Spread operator to keep other properties unchanged
                    title: value            // Only update the 'title' property
                }
            }));
        }
        console.log(this.state);
    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    }

    handleNextStepStatusChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedNextStepsStatus: {}, nextStepsStatus: ""});
        } else {
            this.setState({selectedNextStepsStatus: valueObj, nextStepsStatus: valueObj.key});
        }
    }

    handleAttendeesChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedAttendees: {}});
        } else {
            this.setState({selectedAttendees: valueObj});
            this.setState({assignedUser: valueObj.uniqueId});
        }
    }

    handleProjectsChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedAttendees: []});
        } else {
            this.setState({selectedAttendees: valueObj});
            const attendees = _.map(valueObj, "uniqueId")
            this.setState({sharedWith: attendees});
        }
    }

    handleShareWithChange = (valueObj,event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedAttendees: []});
        } else {
            this.setState({selectedAttendees: valueObj});
            const attendees = _.map(valueObj, "uniqueId")
            this.setState({sharedWith: attendees});
        }
    }


    handleTaskSubmit(event) {
        event.preventDefault();
        NextStepsService.create(this.state).then(response => {
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            if (response.responseStatus === "SUCCESS") {
                this.toggleModal("taskModel")
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    fetchNextStepsBySummary(summaryId) {
        NextStepsService.fetchBySummary(summaryId).then((response) => {
            console.log(response);
            this.setState({nextSteps: response.data});
            this.toggleModal("taskDetailsModel");
        });

    }

    handleSeeMore = (text) => {
        console.log(text);
        this.setState({ summaryModalText: text }, () => {
            // Callback after state is updated
            console.log("After clicking see more...");
            console.log(this.state.summaryModalText); // Now reflects the updated value
            console.log(this.state); // Full updated state
            this.toggleModal("detailedSummaryModal")
        });
    }


    handleUpdateSummarySubmit  ( event)  {
        event.preventDefault();
        console.log("State before submitting .... ", this.state);
        SummaryService.update(this.state).then(response => {
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                "Summary updated successfully, an email will be shared with users who are in the sharing list."
            );
            if (response.responseStatus === "SUCCESS") {
                this.setState({ contentData : response.data})
                this.toggleModal("editSummaryModal")
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    markClosed = (uniqueId) => {
        NextStepsService.markClosed(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                "Task Closed Successfully!"
            );
            NextStepsService.list().then((response) => {
                console.log(response);
                this.setState({completeList: response, list:response});
                if(routeVisibleForRole(["ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3"])){
                    let currentUserId = localStorage.getItem('userId')
                    let filteredList = this.filterTodosByUserId(this.state.completeList, localStorage.getItem('userId'))
                    this.setState({list: filteredList})
                }

            });
        });

    }


    render() {
        const {contentData, index} = this.state;
        let obj = this;
        return (
            <>
                {/*<SimpleHeader*/}
                {/*    name="Details"*/}
                {/*    parentName="Summary"*/}
                {/*    heading="XareGrowth"*/}
                {/*    newBtnUrl="/admin/summary/create"*/}
                {/*    newBtnText="Create Summary"*/}
                {/*/>*/}
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <Card className="mb-4">
                    <CardHeader>
                        <h2 className="mb-0">{"Summary " + index + "  -  " + contentData?.dateCreated}</h2>
                        <h4 className="mb-0">{contentData?.company?.legalName}</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example3cols1Input"
                                    >
                                        Text
                                    </label>
                                    <p>
                                        <SummarySeeMoreDisplay contentData={contentData}
                                        handleSeeMore={this.handleSeeMore}/>
                                    </p>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example3cols2Input"
                                    >
                                        Shared With
                                    </label>
                                    <p>
                                        {contentData?.sharedWith?.map((user) => user?.fullName + ", ")}
                                    </p>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example3cols3Input"
                                    >
                                        Date Created
                                    </label>
                                    <p>{contentData?.dateCreated}</p>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <Button
                                    style={{display:TaskPermissions.create()}}
                                    outline
                                    color="default"
                                    type="button"
                                    onClick={() => this.toggleModal("taskModel")}
                                >
                                    Add Task
                                </Button>
                                <Modal
                                    className="modal-dialog-centered"
                                    size="lg"
                                    isOpen={this.state.taskModel}
                                    toggle={() => this.toggleModal("taskModel")}
                                >
                                    <div className="modal-body p-0">
                                        <Card className="bg-secondary shadow border-0">
                                            <CardHeader className="bg-transparent pb-5">
                                                <div className="text-muted text-center mt-2 mb-3">
                                                    <small>Add Next Steps</small>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="px-lg-5 py-lg-5">
                                                <Form role="form" onSubmit={this.handleTaskSubmit}>


                                                    <NextStepsForm
                                                        onChange={this.handleChange}
                                                        valueObj={this.state}
                                                        errorObj={this.state.errorData}

                                                        selectedNextStepStatus={this.state.selectedNextStepStatus}
                                                        handleNextStepStatusChange={this.handleNextStepStatusChange}
                                                        nextStepStatus={nextStepStatus}

                                                        attendeesSelectkey={this.state.attendeesSelectKey}
                                                        handleAttendeesChange={this.handleAttendeesChange}
                                                        selectedAttendees={this.state.selectedAttendees}
                                                        attendesForSelection={this.state.attendesForSelection}

                                                    />
                                                    <div className="text-center">
                                                        <Button className="my-4" color="default" outline type="submit">
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Modal>
                            </Col>
                            <Col md="4">
                                <Button
                                    outline
                                    color="default"
                                    type="button"
                                    onClick={() => {
                                        this.fetchNextStepsBySummary(contentData.uniqueId)
                                    }}
                                >
                                    View All Task
                                </Button>
                                <Modal
                                    className="modal-dialog-centered"
                                    size="lg"
                                    isOpen={this.state.taskDetailsModel}
                                    toggle={() => this.toggleModal("taskDetailsModel")}
                                >
                                    <div className="modal-body p-0">
                                        <Card className="bg-secondary shadow border-0">
                                            <CardHeader className="bg-transparent pb-5">
                                                <div className="text-muted text-center mt-2 mb-3">
                                                    <small>Next Steps</small>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="px-lg-5 py-lg-5">
                                                <Table className="align-items-center table-flush" responsive>
                                                    <thead className="thead-light">
                                                    <tr>
                                                        <th className="sort" data-sort="name" scope="col">
                                                            #
                                                        </th>
                                                        <th className="sort" data-sort="budget" scope="col">
                                                            Date Created
                                                        </th>
                                                        <th className="sort" data-sort="status" scope="col">
                                                            Todo
                                                        </th>
                                                        <th className="sort" data-sort="status" scope="col">
                                                            By When
                                                        </th>
                                                        <th className="sort" data-sort="status" scope="col">
                                                            Status
                                                        </th>
                                                        <th className="sort" data-sort="status" scope="col">
                                                            User Assigned
                                                        </th>
                                                        <th scope="col"/>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="list">
                                                    {this.state?.nextSteps?.map(function (value, index) {
                                                        return (
                                                            <tr key={index} style={{display: TaskPermissions.read(value?.assignedUser?.uniqueId, value?.createdBy)}}>
                                                                <td className="budget">{index + 1}</td>
                                                                <td className="budget">{value?.dateCreated}</td>
                                                                <td className="budget">{value?.todo}</td>
                                                                <td className="budget">{value?.byWhen}</td>
                                                                <td className="budget">{value?.nextStepsStatus}</td>
                                                                <td className="budget">{value?.assignedUser?.fullName}</td>
                                                                <td className="text-right">
                                                                    {<UncontrolledDropdown>
                                                                            <DropdownToggle
                                                                                color=""
                                                                                size="sm"
                                                                                className="btn-icon-only text-light"
                                                                            >
                                                                                <i className="fas fa-ellipsis-v"/>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu
                                                                                className="dropdown-menu-arrow"
                                                                                right
                                                                            >
                                                                                <DropdownItem
                                                                                    style={{display: TaskPermissions.update(value?.assignedUser?.uniqueId, value?.createdBy)}}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        obj.markClosed(`${value.uniqueId}`);
                                                                                    }}
                                                                                >
                                                                                    Mark Closed
                                                                                </DropdownItem>
                                                                               {/* <DropdownItem
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        obj.props.history.push(
                                                                                            `/admin/content/edit/${value.uniqueId}`
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </DropdownItem>
                                                                                <DropdownItem
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        obj.confirmDeleteAlert(`${value.uniqueId}`);
                                                                                    }}
                                                                                >
                                                                                    Delete
                                                                                </DropdownItem>*/}
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </Table>


                                            </CardBody>
                                        </Card>
                                    </div>
                                </Modal>
                            </Col>
                            <Col md="4">
                                <Button
                                    style={{display:SummaryPermissions.update(contentData?.sharedWith, contentData?.createdBy)}}
                                    outline
                                    color="default"
                                    type="button"
                                    onClick={() => this.toggleModal("editSummaryModal")}
                                >
                                    Update Summary
                                </Button>
                                <Modal
                                    className="modal-dialog-centered"
                                    size="lg"
                                    isOpen={this.state.editSummaryModal}
                                    toggle={() => this.toggleModal("editSummaryModal")}
                                >
                                    <div className="modal-body p-0">
                                        <Card className="bg-secondary shadow border-0">
                                            <CardHeader className="bg-transparent pb-5">
                                                <div className="text-muted text-center mt-2 mb-3">
                                                    <small>Update Summary</small>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="px-lg-5 py-lg-5">
                                                <Form role="form" >


                                                    <SummaryForm onChange={this.handleChange}
                                                                 valueObj={this.state}
                                                                 errorObj={this.state.errorData}
                                                                 attendeesSelectkey={this.state.projectsSelectKey}
                                                                 handleAttendeesChange={this.handleProjectsChange}
                                                                 selectedAttendees={this.state.sharedWithProjects}
                                                                 attendesForSelection={this.state.projectsForSelection}
                                                    />
                                                    <div className="text-center">
                                                        <Button className="my-4" color="default" outline type="button" onClick={this.handleUpdateSummarySubmit}>
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <Modal
                                    className="modal-dialog-centered"
                                    size="lg"
                                    isOpen={this.state.detailedSummaryModal}
                                    toggle={() => this.toggleModal("detailedSummaryModal")}
                                >
                                    <div className="modal-body p-0">
                                        <Card className="bg-secondary shadow border-0">
                                            <CardHeader className="bg-transparent pb-5">
                                                <div className="text-muted text-center mt-2 mb-3">
                                                    <small>Detailed Summary</small>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="px-lg-5 py-lg-5">
                                                <FormGroup className="align-items-center table-flush">
                                                    <p>{this.state.summaryModalText}</p>
                                                </FormGroup>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Modal>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
                <br/>
                <br/>
                <br/>
                <br/>
            </>
        );
    }
}

export default SummaryDetails;

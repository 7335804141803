import PropTypes from "prop-types";
import React from "react";
import { Col, Row, FormGroup } from "reactstrap";
import DownloadUtil from "../../../util/downloadUtil";

const AdminDetailsDummy = ({ adminData, ...props }) => {
  return (
    <>
      <Row>
        <Col md="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols1Input">
              First Name
            </label>
            <p>{adminData.firstName}</p>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols2Input">
              Middle Name
            </label>
            <p>{adminData.middleName ? adminData.middleName : "N/A"}</p>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              Last Name
            </label>
            <p>{adminData.lastName}</p>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4" sm="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="example4cols1Input">
              Email Address
            </label>
            <p>{adminData.emailAddress}</p>
          </FormGroup>
        </Col>
        <Col md="4" sm="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="example4cols2Input">
              Date Created
            </label>
            <p>{adminData.dateCreated}</p>
          </FormGroup>
        </Col>
        <Col md="4" sm="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="example4cols3Input">
              Role
            </label>
            <p>{adminData.role}</p>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4" sm="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="example4cols3Input">
              User Company Name
            </label>
            <p>{adminData?.userCompanyName || "Sekim International"}</p>
          </FormGroup>
        </Col>
        <Col md="4" sm="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="example4cols3Input">
              Brand Logo
            </label>
            <span><img src={DownloadUtil.generateUrl(adminData.brandLogoUID)} alt={"Brand Logo Not Uploaded"}/></span>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

AdminDetailsDummy.propTypes = {
  adminData: PropTypes.object.isRequired,
};

export default AdminDetailsDummy;

import React from "react";
import {Col, Row} from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormReactSelect from "../../../components/common/form/FormReactSelect";

class SummaryForm extends React.Component {
    showField() {
        return this.props.isEdit === true ? "" : "hidden";
    }

    render() {
        return (
            <>
                <Row>
                    <Col md="12">
                        <FormReactSelect
                            selectKey={this.props.attendeesSelectkey}
                            isMulti={true}
                            className="form-control-label"
                            isSearchable={true}
                            label="Share With Projects"
                            name={"sharedWith"}
                            onChange={this.props.handleAttendeesChange}
                            optionValue={"uniqueId"}
                            defaultValue={this.props.selectedAttendees}
                            optionList={this.props.attendesForSelection}
                            optionLabel={"name"}
                            placeHolder="Select Projects"
                            errorMsg={this.props?.errorObj?.attendees || ""}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInput
                            name="text"
                            className="form-control-label"
                            id="text"
                            placeholder="inputPlaceHolder.summary.text"
                            type="textarea"
                            onChange={this.props.onChange}
                            label="inputLabel.summary.text"
                            value={this?.props?.valueObj?.text || ""}
                            errorMsg={this?.props?.errorObj?.text || ""}
                            required
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default SummaryForm;

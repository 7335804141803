/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import ContentService from "../../../services/xgContentService";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import createTextWithTooltip from "../../../util/createTextWithTooltip";
import visibleForRoles from "../../../util/visibleForRoles";
import {ContactPermissions, ContentPermissions} from "../../../services/permissions";

class ContentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      alert: null
    };
    this.delete = this.delete.bind(this);
    this.notify = this.notify.bind(this);
  }

  componentDidMount() {
    ContentService.list().then((response) => {
      console.log(response);
      this.setState({list: response});
    });
  }

  hideDeleteAlert = () => {
    this.setState({
      alert: null
    });
  };

  confirmDeleteAlert = (uniqueId) => {
    this.setState({
      alert: (
          <ReactBSAlert
              warning
              style={{display: "block"}}
              title="Are you sure?"
              onConfirm={() => this.delete(uniqueId)}
              onCancel={this.hideDeleteAlert}
              showCancel
              confirmBtnBsStyle="danger"
              confirmBtnText="Yes, delete it!"
              cancelBtnBsStyle="secondary"
              cancelBtnText="Cancel"
              btnSize=""
          >
            You won't be able to revert this!
          </ReactBSAlert>
      )
    });
  };

  delete(uniqueId) {
    this.setState({alert: null})
    ContentService.delete(uniqueId).then((response) => {
      console.log(response);
      this.notify(
          response.responseStatus.toLowerCase(),
          response.responseStatus,
          response.message
      );
      //TODO: TO be fixed with redux implementation
      //   this.props.history.push("/admin/dashboard");
      ContentService.list().then((response) => {
        console.log(response);
        this.setState({list: response});
      });
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
          <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
            <span data-notify="message">{message}</span>
          </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    let obj = this;
    return (
        <>
          <div className="rna-wrapper">
            <NotificationAlert ref="notificationAlert"/>
          </div>
          <SimpleHeader className={ContentPermissions.create()}
              name="List"
              parentName="Content"
              heading="XareGrwoth"
              // newBtnUrl="/admin/content/create"
              // newBtnText="Create Content"
              // className={ContentPermissions.create()}

          />
          {this.state.alert}
          <Container className="mt--6" fluid >
            <Row >
              <Col xl="12">
                <Card >
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Content List</h3>
                      </div>
                    </Row>
                  </CardHeader>
                  <Table  className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Date Created
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Title
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Category
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Content Type
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Article Types
                      </th>
                      <th scope="col"/>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {this.state.list.map(function (value, index) {
                      return (
                          <tr key={index}>
                            <td className="budget">{index + 1}</td>
                            <td className="budget">{value.dateCreated}</td>
                            <td className="budget">{createTextWithTooltip(value.title) }</td>
                            <td className="budget" >{value.category}</td>
                            <td className="budget">{value.contentType}</td>
                            <td className="budget">{value.articleTypes.map(function(type,index){
                              return <h4 key={index} className="badge badge-lg badge-primary">{type}</h4>
                            })}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                    color=""
                                    size="sm"
                                    className="btn-icon-only text-light"
                                >
                                  <i className="fas fa-ellipsis-v"/>
                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                >
                                  <div>
                                    <DropdownItem className={ContentPermissions.read()}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          obj.props.history.push(
                                              `/admin/content/show/${value.uniqueId}`
                                          );
                                        }}
                                    >
                                      Details
                                    </DropdownItem>
                                  </div>

                                  <DropdownItem
                                      style= {{display:ContentPermissions.update()}}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.props.history.push(
                                            `/admin/content/edit/${value.uniqueId}`
                                        );
                                      }}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                      style= {{display:ContentPermissions.delete()}}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.confirmDeleteAlert(`${value.uniqueId}`);
                                      }}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                      );
                    })}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
    );
  }
}

export default ContentList;

import ProtectedRoute from "./components/auth/ProtectedRoute";
import AdminLayout from "./layouts/Admin";
import React from "react";

const xareGrowthProtectedRoutes = [
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/company/list"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/company/create"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/company/show/:uniqueId"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/company/edit/:uniqueId"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/content/list"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN"]}
        redirectToPath="/auth/login"
        path="/admin/content/create"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/content/show/:uniqueId"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/content/edit/:uniqueId"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/contact/list"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN","ROLE_LEVEL3"]}
        redirectToPath="/auth/login"
        path="/admin/contact/create"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_TENANT_ADMIN","ROLE_LEVEL3"]}
        redirectToPath="/auth/login"
        path="/admin/contact/show/:uniqueId"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/contact/edit/:uniqueId"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/meetingReport/createOld"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/meetingReport/create"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/meetingReport/list"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/meetingReport/edit/:uniqueId"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/meetingReport/show/:uniqueId"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/project/listOld"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/project/list"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/project/create"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/project/show/:uniqueId"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/project/edit/:uniqueId"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/nextSteps/list/"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/meetingReport/company/:uniqueId"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/summary/"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/summary/:companyUid"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/projectSummaries/:projectUid"
        component={(props) => <AdminLayout {...props} />}
    />,
    <ProtectedRoute
        requiredRoleList={["ROLE_ADMIN", "ROLE_SUPER_ADMIN","ROLE_TENANT_ADMIN","ROLE_LEVEL1","ROLE_LEVEL2","ROLE_LEVEL3",]}
        redirectToPath="/auth/login"
        path="/admin/profile"
        component={(props) => <AdminLayout {...props} />}
    />,
]

export default xareGrowthProtectedRoutes;
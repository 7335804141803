/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/pages/dashboards/AdminDashboard.jsx";
import AdminList from "views/pages/admin/List.jsx";
import AdminDetails from "views/pages/admin/Details.jsx";
import AdminCreate from "views/pages/admin/Create.jsx";
import AdminEdit from "../views/pages/admin/Edit";
import TenantSettingList from "../views/pages/tenantSetting/List";
import AdminSettings from "../views/pages/settings/AdminSettings";
import CompanyList from "../views/pages/company/List";
import CompanyCreate from "../views/pages/company/Create";
import CompanyEdit from "../views/pages/company/Edit";
import ContentList from "../views/pages/content/List";
import ContentDetails from "../views/pages/content/Details";
import ContentEdit from "../views/pages/content/Edit";
import ContentCreate from "../views/pages/content/Create";
import ContactCreate from "../views/pages/contact/Create";
import ContactList from "../views/pages/contact/List";
import ContactDetails from "../views/pages/contact/Details";
import ContactEdit from "../views/pages/contact/Edit";
import ReportList from "../views/pages/meetingReport/ReportList";
import MeetingList from "../views/pages/report/List";
import MeetingDetails from "../views/pages/report/Details";
import MeetingEdit from "../views/pages/report/Edit";
import MeetingCreate from "../views/pages/report/Create";
import ProjectList from "../views/pages/project/List";
import ProjectCreate from "../views/pages/project/Create";
import ProjectDetails from "../views/pages/project/Details";
import CompanyDetails from "../views/pages/company/Details";
import routeVisibleForRole from "../util/routeVisibleForRole";
import NextStepsComplete from "../views/pages/nextSteps/NextStepsComplete";
import ReportListByCompany from "../views/pages/report/ReportListByCompany";
import SummaryList from "../views/pages/summary/List";
import ProjectEdit from "../views/pages/project/Edit";
import Profile from "../views/pages/auth/Profile";

const adminRoutes = [
    {
        collapse: true, // Indicates that this is a collapsible group
        state: "userCollapse", // State key for controlling collapse
        name: "Users", // Name of the collapsible group
        icon: "fa fa-users", // Icon for the collapsible group
        invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN","ROLE_ADMIN"]),
        views: [
            {
                collapse: false,
                path: "/list",
                name: "List All Users",
                icon: " ",
                component: AdminList,
                layout: "/admin",
                invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"]),
            },
            {
                collapse: false,
                path: "/show/:uniqueId",
                name: "Admin Details",
                icon: " ",
                component: AdminDetails,
                layout: "/admin",
                invisible: true,
            },
            {
                collapse: false,
                path: "/create",
                name: "Create User",
                icon: " ",
                component: AdminCreate,
                layout: "/admin",
                invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN"]),
            },
            {
                collapse: false,
                path: "/edit/:uniqueId",
                name: "Edit Admin",
                icon: " ",
                component: AdminEdit,
                layout: "/admin",
                invisible: true,
            },
        ],
    },
    {
        collapse: true, // Indicates that this is a collapsible group
        state: "contentCollapse", // State key for controlling collapse
        name: "Content", // Name of the collapsible group
        icon: "fa fa-regular fa-newspaper ", // Icon for the collapsible group
        invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"]),
        views: [ // Array of routes that are part of this collapsible group
            {collapse: false,
                path: "/content/list",
                name: "List All Content",
                icon: " ",
                component: ContentList,
                layout: "/admin",
                invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"]),
            },
            {collapse: false,
                path: "/content/create",
                name: "Content Create",
                icon: " ",
                component: ContentCreate,
                layout: "/admin",
                invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN"]),
            } ,
            {collapse: false,
                path: "/content/approve",
                name: "Approve Content",
                icon: " ",
                component: AdminList,
                layout: "/admin",
                invisible: true
            },
            {collapse: false,
                path: "/content/show/:uniqueId",
                name: "Content Details",
                icon: " ",
                component: ContentDetails,
                layout: "/admin",
                invisible: true,
            },
            {collapse: false,
                path: "/content/edit/:uniqueId",
                name: "Content Edit",
                icon: " ",
                component: ContentEdit,
                layout: "/admin",
                invisible: true,
            },
        ],
    },
    {
        collapse: true, // Indicates that this is a collapsible group
        state: "companyCollapse", // State key for controlling collapse
        name: "Company", // Name of the collapsible group
        icon: "fa fa-solid fa-building ", // Icon for the collapsible group
        invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN","ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL3"]),
        views: [ // Array of routes that are part of this collapsible group
            {collapse: false,
                path: "/company/show/:uniqueId",
                name: "Company Details",
                icon: " ",
                component: CompanyDetails,
                layout: "/admin",
                invisible: true,
            },
            {collapse: false,
                path: "/company/edit/:uniqueId",
                name: "Company Edit",
                icon: " ",
                component: CompanyEdit,
                layout: "/admin",
                invisible: true,
            },
            {collapse: false,
                path: "/company/list",
                name: "List All Companies",
                icon: " ",
                component: CompanyList,
                layout: "/admin",
                invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN","ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL3"])
            },
            {collapse: false,
                path: "/company/create",
                name: "Create New Company",
                icon: " ",
                component: CompanyCreate,
                layout: "/admin",
                invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN","ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL3"]),
            },
        ],
    },
    {
        collapse: true, // Indicates that this is a collapsible group
        state: "contactCollapse", // State key for controlling collapse
        name: "Contact Database", // Name of the collapsible group
        icon: "fa fa-solid fa-address-book ", // Icon for the collapsible group
        invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL3"]),
        views: [
            {collapse: false,
                path: "/contact/show/:uniqueId",
                name: "Contact Details",
                icon: " ",
                component: ContactDetails,
                layout: "/admin",
                invisible: true,
            },
            {collapse: false,
                path: "/contact/edit/:uniqueId",
                name: "Contact Edit",
                icon: " ",
                component: ContactEdit,
                layout: "/admin",
                invisible: true,
            },
            {collapse: false,
                path: "/contact/create/:uniqueId",
                name: "Contact Create",
                icon: " ",
                component: ContactCreate,
                layout: "/admin",
                invisible: true,
            },
            {collapse: false,
                path: "/contact/list",
                name: "List All Contacts",
                icon: " ",
                component: ContactList,
                layout: "/admin",
            },
            {collapse: false,
                path: "/contact/create/",
                name: "Create New Contact",
                icon: " ",
                component: ContactCreate,
                layout: "/admin",
                invisible: false,
            },
        ],
    },
    {
        collapse: true, // Indicates that this is a collapsible group
        state: "projectCollapse", // State key for controlling collapse
        name: "Project Database", // Name of the collapsible group
        icon: "fa fa-briefcase ", // Icon for the collapsible group
        invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN","ROLE_ADMIN", "ROLE_LEVEL3", "ROLE_LEVEL2"]),
        views: [
            {
                path: "/project/list",
                name: "List All Projects",
                icon: " ",
                component: ProjectList,
                layout: "/admin",
                invisible: false
            },
            {
                path: "/project/create",
                name: "Create New Project",
                icon: " ",
                component: ProjectCreate,
                layout: "/admin",
                invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN","ROLE_ADMIN"])
            },
            {
                path: "/project/show/:uniqueId",
                name: "Project Show",
                icon: " ",
                component: ProjectDetails,
                layout: "/admin",
                invisible: true
            },
            {
                path: "/project/edit/:uniqueId",
                name: "Project Edit",
                icon: " ",
                component: ProjectEdit,
                layout: "/admin",
                invisible: true
            },
        ],
    },
    {
        collapse: true, // Indicates that this is a collapsible group
        state: "reportCollapse", // State key for controlling collapse
        name: "Reports", // Name of the collapsible group
        icon: "fa fa-print ", // Icon for the collapsible group
        invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN","ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL3", "ROLE_LEVEL2","ROLE_LEVEL1"]),
        views: [
            {
                path: "/meetingReport/create/:companyId",
                name: "Meeting",
                icon: " ",
                component: MeetingCreate,
                layout: "/admin",
                invisible: true,
            },
            {
                path: "/meetingReport/list",
                name: "List All Meetings",
                icon: " ",
                component: MeetingList,
                layout: "/admin",
                invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN","ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL3"])
            },
            {
                path: "/meetingReport/show/:uniqueId",
                name: "Meeting",
                icon: " ",
                component: MeetingDetails,
                layout: "/admin",
                invisible: true,
            },
            {
                path: "/summary/:companyUid",
                name: "Summaries",
                icon: " ",
                component: SummaryList,
                layout: "/admin",
                invisible: true
            },
            {
                path: "/projectSummaries/:projectUid",
                name: "Summaries By Project",
                icon: " ",
                component: SummaryList,
                layout: "/admin",
                invisible: true
            },
            {
                path: "/summary",
                name: "List All Summaries",
                icon: " ",
                component: SummaryList,
                layout: "/admin",
                invisible: false
            },
            {
                path: "/meetingReport/create",
                name: "Create New Report",
                icon: " ",
                component: MeetingCreate,
                layout: "/admin",
                invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN","ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL3"]),
            },
            {
                path: "/meetingReport/company/:uniqueId",
                name: "Meeting",
                icon: " ",
                component: ReportListByCompany,
                layout: "/admin",
                invisible: true,
            },
            {
                path: "/meetingReport/edit/:uniqueId",
                name: "Meeting",
                icon: " ",
                component: MeetingEdit,
                layout: "/admin",
                invisible: true,
            },
            {
                path: "/nextSteps/list/",
                name: "Tasks",
                icon: " ",
                component: NextStepsComplete,
                layout: "/admin",
                invisible: true
            },
            {
                path: "/project/listOld/",
                name: "Project Events",
                icon: " ",
                component: ReportList,
                layout: "/admin",
                invisible: true,
            },


        ],
    },
    {
        collapse: false,
        path: "/dashboard",
        name: "Timesheet(Coming Soon)",
        icon: "fa fa-hourglass-start",
        component: Dashboard,
        layout: "/admin",
        invisible: false
    },
    {
        collapse: true, // Indicates that this is a collapsible group
        state: "adminCollapse", // State key for controlling collapse
        name: "Admin Settings", // Name of the collapsible group
        icon: " fa fa-cogs", // Icon for the collapsible group
        invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN"]),
        views: [
            {
                path: "/tenantSetting/list",
                name: "Tenant Setting",
                icon: " ",
                component: TenantSettingList,
                layout: "/admin",
                invisible: !routeVisibleForRole(["ROLE_TENANT_ADMIN"])
            },

        ],
    },
    {
        collapse: false,
        path: "/settings",
        name: "Settings",
        icon: "fa-solid fa-gears ",
        component: AdminSettings,
        layout: "/admin",
        invisible: true,
    },
    {
        collapse: false,
        path: "/dashboard",
        name: "Dashboard",
        icon: " ",
        component: Dashboard,
        layout: "/admin",
        invisible: true
    },
    {
        collapse: false,
        path: "/profile",
        name: "Profile",
        icon: " ",
        component: Profile,
        layout: "/admin",
        invisible: true
    },

];


/*
const adminRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: " ",
        component: Dashboard,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/message/list",
        name: "Messages",
        icon: " ",
        component: CollapsableMessageList,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/message/show/:uniqueId",
        name: "Message Details",
        icon: " ",
        component: MessageDetails,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/message/edit/:uniqueId",
        name: "Message Edit",
        icon: " ",
        component: MessageEdit,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/message/create",
        name: "Message Create",
        icon: " ",
        component: MessageCreate,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/lead/list",
        name: "Leads",
        icon: " ",
        component: LeadList,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/lead/show/:uniqueId",
        name: "Section Details",
        icon: " ",
        component: LeadDetails,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/lead/edit/:uniqueId",
        name: "Section Edit",
        icon: " ",
        component: LeadEdit,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/lead/create",
        name: "Section Create",
        icon: " ",
        component: LeadCreate,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/ledger/list",
        name: "Ledger",
        icon: " ",
        component: LedgerList,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/ledger/show/:uniqueId",
        name: "Ledger",
        icon: " ",
        component: LedgerDetailsAdmin,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/ledger/create",
        name: "Ledger",
        icon: " ",
        component: LedgerCreate,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/notice/create/",
        name: "Create Notice",
        icon: " ",
        component: CreateNotice,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/notice/create/",
        name: "Create Notice",
        icon: " ",
        component: CreateNotice,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/notice/",
        name: "Notices",
        icon: " ",
        component: Notices,
        layout: "/admin",
        invisible: false,
    },
    {
        path: "/notices/show/:uniqueId",
        name: "View notice",
        icon: " ",
        component: NoticeDetails,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/notices/edit/:uniqueId",
        name: "Edit Notice",
        icon: " ",
        component: EditNotice,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/notification/list",
        name: "Notification List",
        icon: " ",
        component: NotificationList,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/notification/show/:uniqueId",
        name: "Notification",
        icon: " ",
        component: NotificationDetail,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/notification/edit/:uniqueId",
        name: "Notification",
        icon: " ",
        component: NotificationEdit,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/notificationQueue/list",
        name: "Notification Queue",
        icon: " ",
        component: NotificationQueueList,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/notificationQueue/show/:uniqueId",
        name: "Notification Queue",
        icon: " ",
        component: NotificationQueueDetails,
        layout: "/admin",
        invisible: true,
    },








];
*/

export default adminRoutes;

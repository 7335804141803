/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// react library for routing
import {Link} from "react-router-dom";
// reactstrap components
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Collapse,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Col,
    Row,
    Container,
    Card,
    CardHeader,
    CardBody,
    ListGroup,
    ListGroupItem,
    Carousel,
    CarouselIndicators,
    CarouselItem,
    CarouselCaption, CarouselControl, Modal
} from 'reactstrap';
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import IndexHeader from "components/Headers/IndexHeader.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";
import ContentService from "../services/xgContentService";
import _ from "lodash";
import CompaniesCarousel from "./CompaniesCarousel";
import {countries} from "../util/countries";
import FormReactSelect from "../components/common/form/FormReactSelect";
import {services} from "../util/services";
import NotificationAlert from "react-notification-alert";
import {deliveryPeriods} from "../util/deliveryPeriods";
import UtilService from "../services/utilService";
import Login from "./pages/auth/Login";
import FormInput from "../components/common/form/FormInput";
import ContactUsService from "../services/contactUsService";
import {Helmet} from "react-helmet";

class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectKey: UtilService.uuidv4(),
            loginModal: false,
            contentData: {}
        };
        this.notify = this.notify.bind(this);
        this.renderLogin = this.renderLogin.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        ContentService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({contentData: response.data});
        });
    }


    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    // Convert YouTube URL to embeddable format
     getYouTubeEmbedUrl = (url) => {
        const urlObj = new URL(url);
        if (urlObj.hostname === 'www.youtube.com' && urlObj.searchParams.has('v')) {
            return `https://www.youtube.com/embed/${urlObj.searchParams.get('v')}`;
        }
        return url;
    };

    // Convert Spotify URL to embeddable format
     getSpotifyEmbedUrl = (url) => {
        const urlObj = new URL(url);
        if (urlObj.hostname === 'open.spotify.com') {
            const [, type, id] = urlObj.pathname.split('/');
            return `https://open.spotify.com/embed/${type}/${id}`;
        }
        return url;
    };

     getEmbedUrl = (contentType,content) => {
        if (contentType === 'VIDEO') return this.getYouTubeEmbedUrl(content);
        if (contentType === 'AUDIO') return this.getSpotifyEmbedUrl(content);
        return null;
    };

    renderLogin() {
        return (
            <>
                <Button color="default" className="mb-3" outline
                        onClick={(event) => {
                            event.preventDefault();
                            this.props.history.push("/auth/login");
                        }}
                >
                    Login
                </Button>
            </>
        );
    }

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    onChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    render() {
        const {title, content, contentType, category, uniqueId, dateCreated,seoText, userCompanyName} = this.state.contentData;
        return (
            <div style={{backgroundColor: "white"}}>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={`Read about ${title} in ${category}`}/>
                    <meta property="og:title" content={title}/>
                    <meta property="og:description" content={`Category: ${category}`}/>
                    <meta property="og:type" content="article"/>
                    <meta property="og:url" content={`https://yourwebsite.com/articles/${uniqueId}`}/>
                    <meta property="og:image" content="/path/to/default-thumbnail.jpg"/>
                </Helmet>
    {/*            <Helmet>*/}
    {/*                <script type="application/ld+json">*/}
    {/*                    {`*/}
    {/*  {*/}
    {/*    "@context": "http://schema.org",*/}
    {/*    "@type": "Article",*/}
    {/*    "mainEntityOfPage": {*/}
    {/*      "@type": "WebPage",*/}
    {/*      "@id": "https://yourwebsite.com/articles/${uniqueId}"*/}
    {/*    },*/}
    {/*    "headline": "${title}",*/}
    {/*    "datePublished": "${dateCreated}",*/}
    {/*    "author": {*/}
    {/*      "@type": "Person",*/}
    {/*      "name": "Author Name" // Replace with dynamic author if available*/}
    {/*    },*/}
    {/*    "publisher": {*/}
    {/*      "@type": "Organization",*/}
    {/*      "name": "Your Site Name",*/}
    {/*      "logo": {*/}
    {/*        "@type": "ImageObject",*/}
    {/*        "url": "https://yourwebsite.com/logo.png"*/}
    {/*      }*/}
    {/*    },*/}
    {/*    "image": "/path/to/default-thumbnail.jpg"*/}
    {/*  }*/}
    {/*`}*/}
    {/*                </script>*/}
    {/*            </Helmet>*/}

                {/* Navbar */}
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <Navbar color="white" light expand="md">
                    <NavbarBrand href="/"><img height={"auto"} width={"170px"}
                                               src={require("assets/img/brand/xareLogo.png")}/></NavbarBrand>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <span outline color="default" className="mb-3 xgnav-link"
                                  onClick={(event) => {
                                      event.preventDefault();
                                      this.props.history.push("/");
                                  }}
                            >
                                Services
                            </span>&nbsp;&nbsp;&nbsp;
                            <span outline color="default" className="mb-3 xgnav-link"
                                  onClick={(event) => {
                                      event.preventDefault();
                                      this.props.history.push("/knowledgeCentre");
                                  }}
                            >
                                Knowledge Centre
                            </span>&nbsp;&nbsp;&nbsp;
                            <>
                                {localStorage.getItem("userId") ? (
                                    <span
                                        color="default"
                                        className="mb-3 xgnav-link"
                                        outline
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.props.history.push("/admin/dashboard"); // Navigate to CRM if logged in
                                        }}
                                    >
                                        Switch to CRM
                                    </span>
                                ) : (
                                    <span
                                        color="default"
                                        className="mb-3 xgnav-link"
                                        outline
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.toggleModal("loginModal")
                                        }}
                                    >
                                        Login
                                    </span>
                                )}
                            </>
                        </NavItem>
                    </Nav>
                </Navbar>

                {/* Hero Banner */}
                <div className="hero-banner">
                    <Container fluid>
                        <Row>
                            <Col>
                                <div className="hero-banner-content">
                                    <p style={{textAlign: "start"}}>{title}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <br/>
                <br/>
                <br/>
                {/* Collapsible Sections */}
                {/*<Container className={"mt-10"} style={{    backgroundColor: "white"}}>
                    <Row>
                        <Col>
                            <h1>{title}</h1>
                            <br/>
                            <h3>Published by {userCompanyName} in <i>{category}</i>&nbsp; on &nbsp;{new Date(dateCreated).toLocaleDateString()}</h3>
                            <br/>
                            <div>
                                {contentType === 'TEXT' && (
                                    <div dangerouslySetInnerHTML={{__html: content}}/>
                                )}
                                {(contentType === 'VIDEO' ||  contentType === 'AUDIO') && (
                                    <>
                                        <h3>{seoText}</h3>
                                        <br/>
                                        <iframe
                                            width="1120"
                                            height="630"
                                            src={this.getEmbedUrl(contentType, content)}
                                            title={title}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        />
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>*/}

                <Container className="mt-10" style={{ backgroundColor: "white" }}>
                    <Row>
                        <Col>
                            <h1>{title}</h1>
                            <br />
                            <h3>
                                Published by {userCompanyName} in <i>{category}</i>&nbsp; on &nbsp;
                                {new Date(dateCreated).toLocaleDateString()}
                            </h3>
                            <br />
                            <div>
                                {contentType === "TEXT" && (
                                    <div dangerouslySetInnerHTML={{ __html: content }} />
                                )}
                                {(contentType === "VIDEO" || contentType === "AUDIO") && (
                                    <>
                                        <h3>{seoText}</h3>
                                        <br />
                                        <div style={{ position: "relative", paddingBottom: "56.25%", height: "0", overflow: "hidden" }}>
                                            <iframe
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    left: "0",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                                src={this.getEmbedUrl(contentType, content)}
                                                title={title}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>



                <Modal
                    // size={"lg"}
                    className="modal-dialog-centered"
                    isOpen={this.state.loginModal}
                    toggle={() => this.toggleModal("loginModal")}
                >
                    <Login/>
                </Modal>

                <Container className="mt-10" style={{ backgroundColor: "white", height: "50vh" }}>
                    <Row className="d-flex justify-content-center align-items-center h-500">
                        <Button
                            color="default"
                            className="mb-3"
                            outline
                            onClick={(event) => {
                                event.preventDefault();
                                this.props.history.goBack();
                            }}
                        >
                            Back
                        </Button>
                    </Row>
                </Container>


                <footer className="py-5" id="footer-main">
                    <Container>
                        <Row className="align-items-center justify-content-xl-between">
                            <Col xl="6">
                                <div className="copyright text-center text-xl-left text-muted">
                                    © {new Date().getFullYear()}{" "}
                                    <a
                                        className="font-weight-bold ml-1"
                                        href="https://sekim-international.com/"
                                        target="_blank"
                                    >
                                        Sekim International
                                    </a>
                                </div>
                            </Col>
                            <Col xl="6">
                                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                                    <NavItem>
                                        <NavLink
                                            href="https://xaregrowth.com"
                                            target="_blank"
                                        >
                                            XareGrowth
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href=""
                                            target="_blank"
                                        >
                                            About Us
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href=""
                                            target="_blank"
                                        >
                                            Blog
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href=""
                                            target="_blank"
                                        >
                                            License
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>
        );
    }
}

export default Blog;

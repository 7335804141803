/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import { addUserInfo } from "../../../actions/auth";
import { connect } from "react-redux";
import AuthService from "../../../services/authService";
import {withRouter} from "react-router";
import NotificationAlert from "react-notification-alert";

function mapDispatchToProps(dispatch) {
  return {
    addUserInfo: (userInfo) => dispatch(addUserInfo(userInfo)),
  };
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      roleList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { username, password } = this.state;
    AuthService.login(username, password)
        .then((response) => {
          // On success, reset the state and store tokens
          this.setState({
            username: "",
            password: "",
            roleList: [],
          });

          const { accessToken, roleList, fullName, permissionList, userId } = response;
          localStorage.setItem("CLASHMATE_ACCESS_TOKEN", accessToken);
          localStorage.setItem("userId", userId);
          localStorage.setItem("CLASHMATE_ROLE_LIST", JSON.stringify(roleList));
          localStorage.setItem("CLASHMATE_PERMISSION_LIST", JSON.stringify(permissionList));

          this.props.addUserInfo({ username, roleList, fullName });

          // Switch-case logic to redirect based on role
          switch (String(roleList)) {
            case "ROLE_SUPER_ADMIN":
            case "ROLE_TENANT_ADMIN":
            case "ROLE_LEVEL1":
            case "ROLE_LEVEL2":
            case "ROLE_LEVEL3":
            case "ROLE_ADMIN":
              this.props.history.push("/admin/dashboard");
              break;
            case "ROLE_STUDENT":
              this.props.history.push("/student/dashboard");
              break;
            case "ROLE_TEACHER":
              this.props.history.push("/teacher/dashboard");
              break;
            case "ROLE_PARENT":
              this.props.history.push("/parent/dashboard");
              break;
            default:
              this.props.history.push("/auth/login");
              break;
          }

        })
        .catch((error) => {
          // Handle any errors that occur during the login process
          console.error("Login error:", error);
          alert("Server Unreachable !")
        });
  }

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
          <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
            <span data-notify="message">{message}</span>
          </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };


  render() {
    const { username, password } = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <div className="rna-wrapper">
            <NotificationAlert ref="notificationAlert"/>
          </div>
          <Col lg="12" md="12">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h3>Sign in</h3>
                </div>
                <Form role="form" onSubmit={this.handleSubmit}>
                  <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail,
                      })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                          name="username"
                          placeholder="Email"
                          type="email"
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                          onChange={this.handleChange}
                          value={username}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword,
                      })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                          name="password"
                          placeholder="Password"
                          type="password"
                          onFocus={() =>
                              this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                              this.setState({ focusedPassword: false })
                          }
                          onChange={this.handleChange}
                          value={password}
                      />
                    </InputGroup>
                  </FormGroup>
                  {/*<div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                    />
                    <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>*/}
                  <div className="text-center">
                    <Button className="my-4" color="default" type="submit">
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            {/*<Row className="mt-3">
              <Col xs="4"></Col>
              <Col xs="4">
                <a
                    className="text-light"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
            </Row>*/}
          </Col>
        </Row>
      </>
    );
  }
}

const LoginForm = connect(null, mapDispatchToProps)(Login);

export default withRouter(LoginForm);

/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Card, CardBody, CardHeader, Col, Container, FormGroup, Row,} from "reactstrap";
import ParentService from "../../../services/parentService";
import CompanyService from "../../../services/companyService";

class CompanyDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyData: {},
        };
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        CompanyService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({companyData: response.data});
        });
    }

    render() {
        const {companyData} = this.state;
        return (
            <>
                <SimpleHeader
                    name="Details"
                    parentName="Company"
                    heading="XareGrowth"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">{companyData.legalName}</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Legal Name
                                        </label>
                                        <p>{companyData.legalName}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Country
                                        </label>
                                        <p>
                                            {companyData.country ? companyData.country : "N/A"}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols3Input"
                                        >
                                            Website
                                        </label>
                                        <p>{companyData.website}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols1Input"
                                        >
                                            Company Type
                                        </label>
                                        <p>{companyData.companyTypeList && companyData.companyTypeList.map(function (type, index) {
                                            return <h4 key={index} className="badge badge-lg badge-primary">{type}</h4>
                                        })}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols2Input"
                                        >
                                            Date Created
                                        </label>
                                        <p>{companyData.dateCreated}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols3Input"
                                        >
                                            Address
                                        </label>
                                        <p>{companyData.address}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols3Input"
                                        >
                                            Tax Ref Number
                                        </label>
                                        <p>{companyData.taxRefNumber}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
                <br/>
                <br/>
                <br/>
                <br/>
                {/*<MappingStudentList*/}
                {/*  uniqueId={this.props.match.params.uniqueId}*/}
                {/*  history={this.props.history}*/}
                {/*/>*/}
            </>
        );
    }
}

export default CompanyDetails;
